import Pacientes from "../../components/functional/paciente/Patients.vue";
import Paciente from "../../components/functional/paciente/Patient.vue";
import NovoPaciente from "../../components/functional/paciente/New.vue";

export default [
  {
    path: "pacientes",
    name: "Pacientes",
    component: Pacientes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "paciente",
    name: "Paciente",
    component: Paciente,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "NovoPaciente",
    name: "NovoPaciente",
    component: NovoPaciente,
    meta: {
      requiresAuth: true,
    },
  }
];
