import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    empresas: [],
    empresaId: null,
  },
  mutations: {
    SET_EMPRESA_ID(state, value) {
      state.empresaId = value;
    },
  },
  actions: {
    setEmpresaId({ commit }, id) {
      commit("SET_EMPRESA_ID", id);
    },
    bindEmpresas: firestoreAction((context) => {
      let ref = db.collection("empresas");
      context.bindFirestoreRef("empresas", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    empresas(state) {
      return state.empresas;
    },
    empresaId(state) {
      return state.empresaId;
    },
  },
};
