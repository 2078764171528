import Empresas from "../../components/functional/empresa/Empresas.vue";
import Empresa from "../../components/functional/empresa/Empresa.vue";
import NovaEmpresa from "../../components/functional/empresa/Nova.vue";

export default [
  {
    path: "empresas",
    name: "Empresas",
    component: Empresas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "empresa",
    name: "Empresa",
    component: Empresa,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "NovaEmpresa",
    name: "NovaEmpresa",
    component: NovaEmpresa,
    meta: {
      requiresAuth: true,
    },
  }
];
