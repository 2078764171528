<template>
  <v-card color="transparent " flat tile>
    <v-card-title>
      Profissional
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <prism-editor
        class="my-editor height"
        v-model="profissional"
        :highlight="highlighter"
        line-numbers
      ></prism-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        @click="salvar()"
      >
        Salvar
      </v-btn>
      <v-btn
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'Profissionais' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");

export default {
  components: {},
  data() {
    return {
      profissional: null,
    };
  },
  computed: {
    ...mapGetters({
      profissionalId: "profissionalId",
    }),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    salvar() {
      this.$swal
        .fire({
          title: "Atualizar?",
          text: "Você deseja atualizar esse profissional?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value) {
            let atualizarProfissional = functions.httpsCallable(
              "balancesteady-admin-profissional-atualizar-api"
            );
            await atualizarProfissional({
              profissional: JSON.parse(this.profissional),
            })
              .then((result) => {
                this.$router.replace({ name: "Profissionais" });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },
  },
  async created() {
    let obterProfissional = functions.httpsCallable(
      "balancesteady-admin-profissional-obter-api"
    );
    await obterProfissional({
      profissionalId: this.profissionalId,
    })
      .then((result) => {
        this.profissional = beautify(result.data.profissional, null, 2, 0);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style>
.height {
  height: 650px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
