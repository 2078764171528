<template>
  <v-card color="transparent" flat tile>
    <v-card-title>
      Empresa
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="empresa.cnpj" label="CNPJ" outlined></v-text-field>
      <v-text-field
        v-model="empresa.razaoSocial"
        label="Razão Social"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.nomeComercial"
        label="Nome Comercial"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.telefone"
        label="Telefone"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.email"
        label="Email"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.rua"
        label="Rua/Avenida"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.numero"
        label="Número"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.complemento"
        label="Complemento"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.bairro"
        label="Bairro"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.cidade"
        label="Cidade"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.estado"
        label="Estado"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="empresa.endereco.cep"
        label="CEP"
        outlined
      ></v-text-field>
      <v-card-actions>
        <v-btn
          class="ml-n2"
          v-bind="attrs"
          v-on="on"
          dark
          color="secondary"
          elevation="0"
          @click="adicionar()"
        >
          Adicionar
        </v-btn>
        <v-btn
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          dark
          color="warning"
          elevation="0"
          @click="$router.replace({ name: 'Empresas' })"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";

export default {
  components: {},
  data() {
    return {
      empresa: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    adicionar() {
      let criarEmpresa = functions.httpsCallable(
        "balancesteady-admin-empresa-criar-api"
      );
      criarEmpresa(this.empresa)
        .then((result) => {
          this.$router.replace({ name: "Empresas" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.empresa = {};
    this.empresa.endereco = {};
  },
};
</script>

<style>
.api {
  height: 400px;
  width: 670px;
}
.height {
  height: 450px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
