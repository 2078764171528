import Vue from "vue";
import Vuex from "vuex";
import {vuexfireMutations, firestoreOptions} from "vuexfire";

import patients from "./modules/patients";
import profissionais from "./modules/profissionais";
import empresas from "./modules/empresas";

firestoreOptions.wait = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        patients,
        profissionais,
        empresas,
    },

    mutations: {
        ...vuexfireMutations
    }
});

export default store;
