import Dashboard from "../../components/structural/Dashboard.vue";
import Profissional from "./profissional";
import Empresa from "./empresa";
import Paciente from "./paciente";

export default[{
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
        children: [
            ...Profissional,
            ...Empresa,
            ...Paciente
        ]
    },];
