import Vue from "vue";
import VueRouter from "vue-router";

import { auth } from "../firebase";

import Dashboard from "./modules/dashboard"
import Login from "./modules/login"

Vue.use(VueRouter);

const routes = [
  ...Login,
  ...Dashboard,
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'Balancesteady.ai Administration Console';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = DEFAULT_TITLE;
    });
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
