<template>
  <v-navigation-drawer mini-variant-width="80" class="height-app" app :permanent="true" :mini-variant="true"
    :expand-on-hover="true" :fixed="true" color="primary" :hide-overlay="true" dark>
    <v-list dense nav class="py-0">
      <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar class="profile rounded-lg" size="30">
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Administrador</v-list-item-title>
          <v-list-item-subtitle>Gestão de Configuração</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template>
        <v-list-item link router to="/dashboard/pacientes">
          <v-list-item-action>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Pacientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/profissionais">
          <v-list-item-action>
            <v-icon>mdi-doctor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Profissionais</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/empresas">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template>
        <v-divider dark class="my-4"></v-divider>
        <v-list-item link @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { auth } from "../../firebase";

export default {
  computed: {
    ...mapGetters({}),
  },
  methods: {
    signOut() {
      this.$swal
        .fire({
          title: "Sair do Sistema",
          text: "Você realmente deseja sair do BalanceSteady.ai?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            auth.signOut().then(() => {
              this.$router.replace({
                name: "Login",
              });
            });
          }
        });
    },
  },
};
</script>

<style>

</style>
