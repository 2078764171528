<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Pacientes
			<v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="$router.replace({ name: 'PatientNew' })">
				Adicionar
			</v-btn>
			<v-btn class="ml-4" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="$router.replace({ name: 'PatientBatch' })">
				Carregar
			</v-btn>
			<v-spacer></v-spacer>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line hide-details clearable></v-text-field>
		</v-card-title>
		<v-data-table
			class="elevation-0"
			:headers="headers"
			:items="patients"
			:search="search"
			:items-per-page="20"
			:sort-by="['createdAt']"
			:sort-desc="[false]"
			calculate-widths="true"
		>
			<template v-slot:[`item.phone`]="{ item }">
				{{ formatPhone(item.phone) }}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					@click="editPatient(item)"
					class="mr-1"
					v-bind="attrs"
					v-on="on"
					elevation="0"
					color="primary"
					min-width="0"
					max-width="40"
					min-height="0"
					max-height="40"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn
					@click="deletePatient(item)"
					class="ml-1"
					v-bind="attrs"
					v-on="on"
					elevation="0"
					color="warning"
					min-width="0"
					max-width="40"
					min-height="0"
					max-height="40"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	import { db, adminApp } from "../../../firebase";
	const crypto = require("../../../utils/crypto");
	import { formatToCPF, formatToPhone } from "brazilian-values";

	export default {
		data() {
			return {
				search: null,
				headers: [
					{ text: "ID", value: "id", sortable: false },
					{ text: "Dev", value: "dev", sortable: false },
					{ text: "Nome", value: "fullName" },
					{ text: "Contato", value: "phone" },
					{ text: "UserId", value: "userId" },
					{ text: "CNPJ", value: "company.cnpj" },
					{ text: "Ações", value: "actions", sortable: false },
				],
			};
		},
		computed: {
			...mapGetters({
				patients: "patients",
			}),
		},
		watch: {},
		methods: {
			formatPhone(item) {
				return formatToPhone(item);
			},
			editPatient(patient) {
				this.$store.dispatch("setPatientId", patient.id);
				this.$router.replace({ name: "Patient" });
			},
			deletePatient(patient) {
				this.$swal
					.fire({
						title: "Apagar?",
						text: "Você deseja apagar esse paciente?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Sim",
						cancelButtonText: "Não",
					})
					.then((result) => {
						if (result.value) {
							db.collection("patient")
								.doc(patient.id)
								.delete()
								.then(async () => {
									adminApp
										.auth()
										.signInWithEmailAndPassword(
											patient.userId.replaceAll(".", "").replaceAll("-", "") + "@icareyou.com",
											await crypto.decrypt(patient.accessCode, db)
										)
										.then((userCredential) => {
											// Signed in
											userCredential.user.delete().then(() => {
												db.collection("patient-map")
													.where("patient", "==", patient.id)
													.get()
													.then((querySnapshot) => {
														querySnapshot.forEach(async (map) => {
															await db
																.collection("patient-map")
																.doc(map.id)
																.delete();
														});
													});
												this.$router.replace({ name: "Patients" });
											});
											// ...
										})
										.catch((error) => {
											console.error(error);
										});
								});
						}
					});
			},
		},
		created() {
			this.$store.dispatch("bindPatients");
		},
	};
</script>
