<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Empresas
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="$router.replace({ name: 'NovaEmpresa' })"
      >
        Adicionar
      </v-btn>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="empresas"
      :search="search"
      :items-per-page="20"
      :sort-by="['createdAt']"
      :sort-desc="[false]"
      calculate-widths="true"
    >
      <template v-slot:item.telefone="{ item }">
        {{ formatarTelefone(item.telefone) }}
      </template>

      <template v-slot:item.ativo="{ item }">
        <v-switch
          class="mb-4"
          hide-details
          inset
          v-model="item.ativo"
          @click="mudarEstadoEmpresa(item)"
        ></v-switch>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="editarEmpresa(item)"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          min-width="0"
          max-width="30"
          min-height="0"
          max-height="30"
          text
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          @click="apagarEmpresa(item)"
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="warning"
          min-width="0"
          max-width="30"
          min-height="0"
          max-height="30"
          text
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { formatToPhone } from "brazilian-values";

export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Ativo", value: "ativo" },
        { text: "Nome Comercial", value: "nomeComercial" },
        { text: "Razão Social", value: "razaoSocial" },
        { text: "Contato", value: "telefone" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      empresas: "empresas",
    }),
  },
  watch: {},
  methods: {
    formatarTelefone(item) {
      return formatToPhone(item);
    },
    async mudarEstadoEmpresa(empresa) {
      let mudarEstadoEmpresa = functions.httpsCallable(
        "balancesteady-admin-empresa-mudar-estado-api"
      );
      await mudarEstadoEmpresa({
        empresa: empresa,
      })
        .then((result) => {
          console.info("Empresa com estado modificado para: ", empresa.active);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editarEmpresa(empresa) {
      this.$store.dispatch("setEmpresaId", empresa.cnpj);
      this.$router.replace({ name: "Empresa" });
    },
    apagarEmpresa(empresa) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar essa empresa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value) {
            let apagarEmpresa = functions.httpsCallable(
              "balancesteady-admin-empresa-apagar-api"
            );
            await apagarEmpresa({
              empresa: empresa,
            })
              .then((result) => {
                console.info("Empresa removida!");
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindEmpresas");
  },
};
</script>
