<template>
	<v-app id="login">
		<v-main>
			<v-card class="d-flex justify-space-around flex-wrap fill-height transparent">
						<v-card class="" color="white" elevation="0" :loading="loading">
									<v-img src="~@/assets/logo/logo.png" contain align="center" max-height="400" style="margin: 0 auto"></v-img>
							<v-card-text class="mt-n8">
								<v-form>
									<v-text-field
										label="Usuário"
										name="email"
										v-model="email"
										prepend-icon="mdi-account"
										type="email"
										outlined
										clearable
										:rules="[rules.required, rules.email]"
									></v-text-field>
									<v-text-field
										id="password"
										label="Senha"
										name="password"
										prepend-icon="mdi-lock"
										type="password"
										outlined
										clearable
										:rules="[rules.required]"
										v-model="password"
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn @click="login" color="primary" dark block elevation="0">Entrar</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
							<v-card-actions>
								<p class="text-center caption amber--text">
									Administração do Sistema de Inteligência em Fisioterapia Neurofunctional - v1.0.0
								</p>
							</v-card-actions>
						</v-card>
			</v-card>
		</v-main>
	</v-app>
</template>

<style>
</style>

<script>
	import { auth } from "../firebase";

	export default {
		data() {
			return {
				loading: false,
				email: "",
				password: "",
				error: null,
				rules: {
					required: (value) => !!value || "Campo obrigatório.",
					email: (value) => {
						const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Formato de email inválido!";
					},
				},
			};
		},
		methods: {
			login() {
				this.error = false;
				this.loading = !this.loading;
				auth
					.signInWithEmailAndPassword(this.email, this.password)
					.then((userCredential) => {
						this.loading = !this.loading;
					})
					.catch((err) => {
						this.loading = !this.loading;
						this.$swal.fire({
							text: err.message,
							icon: "error",
							confirmButtonText: "Ok",
						});
					});
			},
		},
	};
</script>
