<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Profissionais
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="$router.replace({ name: 'NovoProfissional' })"
      >
        Adicionar
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="profissionais"
      :search="search"
      :items-per-page="20"
      :sort-by="['nome']"
      :sort-desc="[false]"
      calculate-widths="true"
    >
      <template v-slot:item.celular="{ item }">
        {{ formatPhone(item.celular) }}
      </template>

      <template v-slot:item.ativo="{ item }">
        <v-switch
          class="mb-4"
          hide-details
          inset
          v-model="item.ativo"
          @click="mudarEstadoProfissional(item)"
        ></v-switch>
      </template>

      <template v-slot:item.possuiContaPessoal="{ item }">
        <v-switch
          class="mb-4"
          hide-details
          inset
          v-model="item.possuiContaPessoal"
          @click="mudarEstadoProfissional(item)"
        ></v-switch>
      </template>

      <template v-slot:item.vinculadoEmpresa="{ item }">
        <v-switch
          class="mb-4"
          hide-details
          inset
          v-model="item.vinculadoEmpresa"
          @click="mudarEstadoProfissional(item)"
        ></v-switch>
      </template>


      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="editProfessional(item)"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          min-width="0"
          max-width="30"
          min-height="0"
          max-height="30"
          text
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          @click="deleteProfessional(item)"
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="error"
          min-width="0"
          max-width="30"
          min-height="0"
          max-height="30"
          text
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { formatToPhone } from "brazilian-values";

export default {
  data() {
    return {
      search: null,
      profissionalAtivo: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Ativo", value: "ativo" },
        { text: "Conta Pessoal", value: "possuiContaPessoal" },
        { text: "Vínculo Empresa", value: "vinculadoEmpresa" },
        { text: "e-mail", value: "email" },
        { text: "Nome", value: "nome" },
        { text: "Sobrenome", value: "sobrenome" },
        { text: "Conselho", value: "conselho" },
        { text: "Contato", value: "celular" },
        { text: "Cidade", value: "endereco.cidade" },
        { text: "Estado", value: "endereco.estado" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profissionais: "profissionais",
    }),
  },
  watch: {},
  methods: {
    async mudarEstadoProfissional(profissional) {
      let mudarEstadoProfissional = functions.httpsCallable(
        "balancesteady-admin-profissional-mudar-estado-api"
      );
      await mudarEstadoProfissional({
        profissional: profissional,
      })
        .then((result) => {
          console.info(
            "Profissional com estado modificado para: ",
            profissional.active
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatPhone(item) {
      return formatToPhone(item);
    },
    editProfessional(professional) {
      this.$store.dispatch("setProfissionalId", professional.id);
      this.$router.replace({ name: "Profissional" });
    },
    deleteProfessional(profissional) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar esse profissional?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value) {
            let apagarProfissional = functions.httpsCallable(
              "balancesteady-admin-profissional-apagar-api"
            );
            await apagarProfissional({
              profissional: profissional,
            })
              .then((result) => {
                console.info("Profissional removido!");
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindProfissionais");
  },
};
</script>
