import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    patients: [],
    patientId: null,
  },
  mutations: {
    SET_PATIENT_ID(state, value) {
      state.patientId = value;
    },
  },
  actions: {
    setCustomerId({ commit }, id) {
      commit("SET_PATIENT_ID", id);
    },
    bindPatients: firestoreAction((context) => {
      let ref = db.collection("patient");
      context.bindFirestoreRef("patients", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    patients(state) {
      return state.patients;
    },
    patientId(state) {
      return state.patientId;
    },
  },
};
