"use strict";
const crypto = require("crypto");

const IV_LENGTH = 16; // For AES, this is always 16

async function getKey(db) {
  return db.collection("admin")
    .doc("config")
    .get()
    .then((config) => {
      return config.data().key;
    });
}

async function encrypt(text, db) {
  let key = await getKey(db);
  let iv = crypto.randomBytes(IV_LENGTH);
  let cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(key), iv);
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString("hex") + ":" + encrypted.toString("hex");
}

async function decrypt(text, db) {
  let key = await getKey(db);
  let textParts = text.split(":");
  let iv = Buffer.from(textParts.shift(), "hex");
  let encryptedText = Buffer.from(textParts.join(":"), "hex");
  let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
}

module.exports = { decrypt, encrypt };
