import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
// Translation provided by Vuetify (javascript)
import pt from "vuetify/es5/locale/pt";
import colors from "vuetify/es5/util/colors";

Vuetify.config.silent = false;

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.base,
        secondary: colors.blue.base,
        accent: colors.lightBlue.base,
        error: colors.deepOrange.base,
        warning: colors.amber.base,
        info: colors.cyan.base,
        success: colors.teal.base,
      },
    },
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  lang: {
		locales: { pt },
		current: "ptbr",
	},
});
