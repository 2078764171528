<template>
  <v-card color="transparent" flat tile>
    <v-card-title>
      Profissional
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="profissional.nome"
        label="Nome"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.sobrenome"
        label="Sobrenome"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.celular"
        label="Celular"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.email"
        label="Email"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.cpf"
        label="CPF"
        outlined
      ></v-text-field>
      <v-select
        :items="crefitos"
        label="Conselho Regional"
        outlined
        v-model="profissional.regional"
      ></v-select>
      <v-text-field
        v-model="profissional.conselho"
        label="CREFITO"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.rua"
        label="Rua/Avenida"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.numero"
        label="Número"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.complemento"
        label="Complemento"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.bairro"
        label="Bairro"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.cidade"
        label="Cidade"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.estado"
        label="Estado"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="profissional.endereco.cep"
        label="CEP"
        outlined
      ></v-text-field>
      <v-card-actions>
        <v-btn
          class="ml-n2"
          v-bind="attrs"
          v-on="on"
          dark
          color="secondary"
          elevation="0"
          @click="adicionar()"
        >
          Adicionar
        </v-btn>
        <v-btn
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          dark
          color="warning"
          elevation="0"
          @click="$router.replace({ name: 'Profissionais' })"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";

export default {
  components: {},
  data() {
    return {
      profissional: null,
      crefitos: [
        "CREFITO-1",
        "CREFITO-2",
        "CREFITO-3",
        "CREFITO-4",
        "CREFITO-5",
        "CREFITO-6",
        "CREFITO-7",
        "CREFITO-8",
        "CREFITO-9",
        "CREFITO-10",
        "CREFITO-11",
        "CREFITO-12",
        "CREFITO-13",
        "CREFITO-14",
        "CREFITO-15",
        "CREFITO-16",
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    adicionar() {
      let createProfessional = functions.httpsCallable(
        "balancesteady-admin-profissional-criar-api"
      );
      createProfessional(this.profissional)
        .then((result) => {
          this.$router.replace({ name: "Profissionais" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.profissional = {};
    this.profissional.endereco = {};
  },
};
</script>

<style>
.api {
  height: 400px;
  width: 670px;
}
.height {
  height: 450px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
