import Profissionais from "../../components/functional/profissional/Profissionais.vue";
import Profissional from "../../components/functional/profissional/Profissional.vue";
import NovoProfissional from "../../components/functional/profissional/Novo.vue";

export default [
  {
    path: "profissionais",
    name: "Profissionais",
    component: Profissionais,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "profissional",
    name: "Profissional",
    component: Profissional,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "novoProfissional",
    name: "NovoProfissional",
    component: NovoProfissional,
    meta: {
      requiresAuth: true,
    },
  },
];
