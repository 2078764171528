import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    profissionais: [],
    profissionalId: null,
  },
  mutations: {
    SET_PROFISSIONAL_ID(state, value) {
      state.profissionalId = value;
    },
  },
  actions: {
    setProfissionalId({ commit }, id) {
      commit("SET_PROFISSIONAL_ID", id);
    },
    bindProfissionais: firestoreAction((context) => {
      let ref = db.collection("profissionais");
      context.bindFirestoreRef("profissionais", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    profissionais(state) {
      return state.profissionais;
    },
    profissionalId(state) {
      return state.profissionalId;
    },
  },
};
